import { css } from '@emotion/react';
import { FormattedMessage } from 'react-intl';

import {
  IInputRadio,
  InputRadioGroup,
  ShoppingBagIcon,
  ShoppingCartIcon,
  spacings,
  theme,
  Typography,
} from '@dialog/design-system';
import { AddToCartIcons } from '@dialog/search-contracts';

const items: IInputRadio<AddToCartIcons>[] = [
  {
    id: `add-to-cart-icon-${AddToCartIcons.CART}`,
    value: AddToCartIcons.CART,
    children: <ShoppingCartIcon />,
  },
  {
    id: `add-to-cart-icon-${AddToCartIcons.BAG}`,
    value: AddToCartIcons.BAG,
    children: <ShoppingBagIcon />,
  },
];

export const AddToCartIconSelection = ({
  value,
  onChange,
}: {
  value?: AddToCartIcons;
  onChange: (value: AddToCartIcons) => void;
}): JSX.Element => {
  return (
    <div
      css={css({ display: 'flex', gap: spacings.lg, flexDirection: 'column' })}
    >
      <div
        css={css({
          display: 'flex',
          gap: spacings.xs,
          flexDirection: 'column',
        })}
      >
        <Typography variant="base-md">
          <FormattedMessage id="settings.widget-settings.shoppingIconTitle" />
        </Typography>
        <Typography variant="xs-reg" color={theme.palette.grey600}>
          <FormattedMessage id="settings.widget-settings.shoppingIconDescription" />
        </Typography>
      </div>
      <div>
        <InputRadioGroup<AddToCartIcons>
          size="md"
          items={items}
          classicRenderInputRadio
          name="select-add-to-cart-icon"
          id="select-add-to-cart-icon"
          onChange={onChange}
          selectedValue={value}
        />
      </div>
    </div>
  );
};
