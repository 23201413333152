import { Filter, FILTER_OPERATORS } from '../types';

export const isListValue = <T = string>(value: unknown): value is Array<T> =>
  Array.isArray(value);

export const isValidMetafield = (meta: {
  id: string;
  key: string;
  value: string;
  __parentId: string;
}): boolean => {
  const isStringValue =
    typeof meta.value === 'string' && Number.isNaN(parseInt(meta.value));

  return isStringValue;
};

export const hasFilters = (filters?: Partial<Filter>[]): boolean => {
  return filters !== undefined && filters.length !== 0;
};

const isCompleteFilter = (filter: Partial<Filter>): filter is Filter => {
  if (filter.column === 'unsaved') return true;

  return (
    (filter.column !== 'placeholder' &&
      filter.value !== undefined &&
      filter.operator !== undefined) ||
    (filter.column !== 'placeholder' &&
      filter.value === undefined &&
      filter.operator !== undefined &&
      [FILTER_OPERATORS.IS_EMPTY, FILTER_OPERATORS.IS_NOT_EMPTY].includes(
        filter.operator,
      ))
  );
};

export const isCompleteFilters = (
  filters?: Partial<Filter>[],
): filters is Filter[] => {
  if (filters === undefined) return false;

  return filters.every(isCompleteFilter);
};

export const getCompleteFilters = (filters?: Partial<Filter>[]): Filter[] => {
  const completedFilters = filters?.filter(isCompleteFilter);

  return isCompleteFilters(completedFilters) ? completedFilters : [];
};
