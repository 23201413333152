import { getAxiosRequest } from '@swarmion/serverless-contracts';

import {
  getImportStatusContract,
  ImportStatus,
} from '@dialog/organization-contracts';
import {
  GenerateOrTranslateParams,
  generateOrTranslateProductPageQuestionsContract,
  getProductPageQuestionsContract,
  ProductPageQuestionsList,
  saveProductPageQuestionsContract,
} from '@dialog/search-contracts';

import { createAuthClient } from 'services/networking/client';

export const getProductPageQuestions = async ({
  organizationSlug,
  locale,
  prev,
  next,
  limit,
}: {
  organizationSlug: string;
  locale?: string;
  prev?: { pk: string; sk: string };
  next?: { pk: string; sk: string };
  limit?: number;
}): Promise<ProductPageQuestionsList> => {
  const response = await getAxiosRequest<
    typeof getProductPageQuestionsContract
  >(getProductPageQuestionsContract, await createAuthClient(), {
    queryStringParameters: {
      locale,
      limit: limit?.toString(),
      pkNext: next?.pk,
      skNext: next?.sk,
      pkPrev: prev?.pk,
      skPrev: prev?.sk,
    },
    pathParameters: { organizationSlug },
  });

  return response.data;
};

export const getImportStatus = async ({
  organizationSlug,
}: {
  organizationSlug: string;
}): Promise<ImportStatus> => {
  const response = await getAxiosRequest<typeof getImportStatusContract>(
    getImportStatusContract,
    await createAuthClient(),
    {
      pathParameters: { organizationSlug },
    },
  );

  return response.data;
};

export const saveProductPageQuestions = async (
  organizationSlug: string,
  productPageQuestions?: ProductPageQuestionsList['items'],
): Promise<ProductPageQuestionsList['items'] | void> => {
  const response = await getAxiosRequest(
    saveProductPageQuestionsContract,
    await createAuthClient(),
    {
      body: productPageQuestions ?? [],
      pathParameters: {
        organizationSlug,
      },
    },
  );

  return response.data;
};

export const generateOrTranslateProductPageQuestions = async (
  data: GenerateOrTranslateParams,
): Promise<void> => {
  const response = await getAxiosRequest(
    generateOrTranslateProductPageQuestionsContract,
    await createAuthClient(),
    {
      body: {
        data,
      },
      pathParameters: {
        organizationSlug: data.organizationSlug,
      },
    },
  );

  return response.data;
};
