import { Navigate } from 'react-router-dom';

import { useAuth } from 'components/AuthProvider/AuthProvider';

const ProtectedRoute = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { session } = useAuth();

  if (session === undefined) {
    window.localStorage.setItem('previousPath', window.location.href);

    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
