/* eslint max-lines: ["error", 270] */

import { Product, ProductQuestion } from '@dialog/search-contracts';

import { SPLITTER_CHARS } from '../constants';
import {
  Filter,
  FILTER_OPERATORS,
  FilterOption,
  ProductQuestionsFilters,
  ProductQuestions as ProductQuestionsI,
  TypeFilter,
} from '../types';
import {
  getCompleteFilters,
  hasFilters,
  isListValue,
  isValidMetafield,
} from './validation';

export const ignoreFiltersColumns: Array<
  keyof ProductQuestionsFilters | 'unsaved' | 'placeholder'
> = ['productName', 'unsaved'];

export const retrieveProductQuestionsFilter = (
  products: Product[],
): ProductQuestionsFilters | undefined => {
  if (products.length === 0) return;
  const collections: FilterOption[] = [];
  const productType: FilterOption[] = [];
  const metadata: FilterOption[] = [];

  products.forEach((item: Product) => {
    item.collections?.forEach(collection => {
      const exist = collections.find(filter => filter.id === collection.id);
      if (exist === undefined) {
        collections.push({
          id: collection.id,
          label: collection.title ?? collection.id,
        });
      }
    });

    item.metadata?.forEach(meta => {
      const exist = metadata.find(filter => filter.id === meta.key);
      if (exist === undefined && isValidMetafield(meta)) {
        metadata.push({
          id: meta.key,
          label: meta.key,
        });
      }
    });

    const exist = productType.find(filter => filter.id === item.productType);
    if (exist === undefined && item.productType !== '') {
      productType.push({
        id: item.productType ?? '',
        label: item.productType ?? '',
      });
    }
  });

  return {
    collections,
    metadata,
    productType,
  };
};

export const getTypeFilter = (
  field: keyof ProductQuestionsFilters,
): TypeFilter => {
  switch (field) {
    case 'questions':
      return 'field';
    case 'collections':
    case 'productType':
      return 'multiSelect';
    case 'metadata':
      return 'keyValue';
    default:
      return 'string';
  }
};

// eslint-disable-next-line complexity
export const isMatched = (
  operator: FILTER_OPERATORS,
  value?: string | string[],
  comparedValue?: string | string[],
): boolean => {
  switch (operator) {
    case FILTER_OPERATORS.IS_EMPTY:
      return comparedValue === undefined || comparedValue === '';
    case FILTER_OPERATORS.IS_NOT_EMPTY:
      return comparedValue !== undefined && comparedValue !== '';
    case FILTER_OPERATORS.ALL_OF:
      if (isListValue(value) && isListValue(comparedValue)) {
        return value.every(val => comparedValue.includes(val));
      }
      if (typeof value === 'string' && isListValue(comparedValue)) {
        return comparedValue.includes(value);
      }
      if (isListValue(value) && typeof comparedValue === 'string') {
        return value.includes(comparedValue);
      }

      return false;
    case FILTER_OPERATORS.ANY_OF:
      if (isListValue(value) && isListValue(comparedValue)) {
        return value.some(val => comparedValue.includes(val));
      }
      if (typeof value === 'string' && isListValue(comparedValue)) {
        return comparedValue.includes(value);
      }
      if (isListValue(value) && typeof comparedValue === 'string') {
        return value.includes(comparedValue);
      }

      return false;
    case FILTER_OPERATORS.NONE_OF:
      if (isListValue(value) && isListValue(comparedValue)) {
        return value.every(val => !comparedValue.includes(val));
      }
      if (typeof value === 'string' && isListValue(comparedValue)) {
        return !comparedValue.includes(value);
      }
      if (isListValue(value) && typeof comparedValue === 'string') {
        return !value.includes(comparedValue);
      }

      return false;
    case FILTER_OPERATORS.CONTAINS:
      if (typeof value === 'string' && typeof comparedValue === 'string') {
        const match = new RegExp(`${value}`, 'gi');

        return comparedValue.match(match) !== null;
      }

      return false;
    case FILTER_OPERATORS.DOES_NOT_CONTAIN:
      if (typeof value === 'string' && typeof comparedValue === 'string') {
        const match = new RegExp(`${value}`, 'gi');

        return comparedValue.match(match) === null;
      }

      return false;
    case FILTER_OPERATORS.START_WITH:
      if (typeof value === 'string' && typeof comparedValue === 'string') {
        const match = new RegExp(`^${value}`, 'gi');

        return comparedValue.match(match) !== null;
      }

      return false;
    case FILTER_OPERATORS.ENDS_WITH:
      if (typeof value === 'string' && typeof comparedValue === 'string') {
        const match = new RegExp(`${value}$`, 'gi');

        return comparedValue.match(match) !== null;
      }

      return false;
    case FILTER_OPERATORS.IS_EQUAL:
    default:
      if (typeof value === 'string' && typeof comparedValue === 'string') {
        return comparedValue === value;
      }

      return false;
  }
};

export const filterProductPageQuestion = (
  productPageQuestions: ProductQuestionsI[],
  filters?: Partial<Filter>[],
): ProductQuestionsI[] => {
  if (!hasFilters(filters)) {
    return productPageQuestions;
  }

  const completeFilters = getCompleteFilters(filters);

  return productPageQuestions.filter(productPageQuestion => {
    // eslint-disable-next-line complexity
    return completeFilters.every(filter => {
      if (filter.column === 'placeholder') return true;

      if (filter.column === 'unsaved') {
        const hasUnsavedChanges = productPageQuestion.previewQuestions?.some(
          (previewQuestion, index) =>
            previewQuestion.question !==
            productPageQuestion.questions[index]?.question,
        );

        return Boolean(hasUnsavedChanges);
      }

      if (filter.column === 'productName' && typeof filter.value === 'string') {
        const matchingProductName = new RegExp(`${filter.value}`, 'gi');

        return (
          productPageQuestion.productName.match(matchingProductName) !== null
        );
      }

      const columnValues = productPageQuestion[filter.column];
      const typeFilter = getTypeFilter(filter.column);

      if (typeFilter === 'field') {
        if (
          isListValue<ProductQuestion>(columnValues) &&
          filter.column === 'questions'
        ) {
          if (filter.operator === FILTER_OPERATORS.IS_NOT_EMPTY) {
            return columnValues.every(item =>
              isMatched(filter.operator, undefined, item.question),
            );
          }

          // NOTE: using some instead of every because some have 3 questions and not 2, the 3 may be not be empty and give wrong result
          return columnValues.some(item =>
            isMatched(filter.operator, undefined, item.question),
          );
        }

        if (typeof columnValues === 'string') {
          return isMatched(filter.operator, undefined, columnValues);
        }

        return false;
      }

      if (filter.column === 'metadata' && typeof filter.value === 'string') {
        const [key, value] = filter.value.split(SPLITTER_CHARS);
        const metadata = productPageQuestion.metadata?.find(
          meta => meta.key === key,
        );

        return isMatched(filter.operator, value, metadata?.value);
      }

      if (
        (typeof columnValues === 'string' &&
          typeof filter.value === 'string') ||
        (typeof columnValues === 'string' && isListValue(filter.value))
      ) {
        return isMatched(filter.operator, filter.value, columnValues);
      }

      if (
        isListValue(filter.value) &&
        columnValues !== undefined &&
        isListValue<{ id: string }>(columnValues)
      ) {
        return isMatched(
          filter.operator,
          filter.value,
          columnValues.map(i => i.id),
        );
      }

      return false;
    });
  });
};
