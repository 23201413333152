/* eslint-disable max-lines */
import { v4 as uuidv4 } from 'uuid';

import { NotFound } from 'components';
import { RoutePaths } from 'config';
import { ProductQuestionsFiltersProvider } from 'pages/ProductQuestions/contexts/ProductQuestionsFiltersProvider';
import { ProductQuestionsProvider } from 'pages/ProductQuestions/contexts/ProductQuestionsProvider';
import WidgetSettings from 'pages/Settings/WidgetSettings/WidgetSettings';
import { lazyWithRetry } from 'utils';

const RootWrapper = lazyWithRetry(() => import('pages/RootWrapper'));

const HomeWrapper = lazyWithRetry(() => import('pages/Home/HomeWrapper'));

const ProductQuestions = lazyWithRetry(
  () => import('pages/ProductQuestions/ProductQuestions'),
);

const SignUp = lazyWithRetry(() => import('pages/SignUp/components/SignUp'));
const Onboarding = lazyWithRetry(
  () => import('pages/Onboarding/components/Onboarding'),
);
const CreateOrganization = lazyWithRetry(
  () => import('pages/Onboarding/CreateOrganization/CreateOrganization'),
);
const OnboardingOrganizationInvitation = lazyWithRetry(
  () =>
    import(
      'pages/OnboardingOrganizationInvitation/components/OnboardingOrganizationInvitation'
    ),
);
const Login = lazyWithRetry(() => import('pages/Login/Login'));
const FinishSignIn = lazyWithRetry(
  () => import('pages/FinishSignIn/FinishSignIn'),
);
const Settings = lazyWithRetry(
  () => import('pages/Settings/components/Settings'),
);

const General = lazyWithRetry(
  () => import('pages/Settings/components/General'),
);
const Usage = lazyWithRetry(() => import('pages/Settings/Usage/Usage'));
const Profile = lazyWithRetry(
  () => import('pages/Settings/components/Profile'),
);
const Members = lazyWithRetry(
  () => import('pages/Settings/Members/components/Members'),
);
const Customization = lazyWithRetry(
  () => import('pages/Settings/Customization/Customization'),
);
const Dashboards = lazyWithRetry(() => import('pages/Dashboards/Dashboards'));
const Cockpit = lazyWithRetry(() => import('pages/Dashboards/Cockpit/Cockpit'));
const Playground = lazyWithRetry(() => import('pages/Playground/Playground'));

export interface IRoutesTreeConfiguration {
  id: string;
  path?: string;
  element: JSX.Element;
  index?: boolean;
  onlyLoggedOut?: boolean;
  suspense?: boolean;
  protected?: boolean;
  requireOrganization?: boolean;
  children?: IRoutesTreeConfiguration[];
}
export const routesTreeConfiguration: IRoutesTreeConfiguration[] = [
  {
    id: uuidv4(),
    path: RoutePaths.CREATE_ORGANIZATION,
    protected: true,
    requireOrganization: false,
    element: <CreateOrganization />,
  },
  {
    id: uuidv4(),
    path: RoutePaths.HOME_PAGE,
    element: <RootWrapper />,
    suspense: true,
    children: [
      {
        id: uuidv4(),
        element: <HomeWrapper />,
        index: true,
        protected: true,
        suspense: true,
        requireOrganization: true,
        path: RoutePaths.HOME_PAGE,
      },
      {
        id: uuidv4(),
        path: RoutePaths.PRODUCT_QUESTIONS_PAGE,
        element: (
          <ProductQuestionsFiltersProvider>
            <ProductQuestionsProvider>
              <ProductQuestions />
            </ProductQuestionsProvider>
          </ProductQuestionsFiltersProvider>
        ),
        protected: true,
        suspense: true,
        requireOrganization: true,
      },
      {
        id: uuidv4(),
        path: RoutePaths.PLAYGROUND,
        element: <Playground />,
        protected: true,
        suspense: true,
        requireOrganization: true,
      },
      {
        id: uuidv4(),
        path: RoutePaths.DASHBOARDS_PAGE,
        element: <Dashboards />,
        protected: true,
        requireOrganization: true,
        suspense: true,
        children: [
          {
            index: true,
            id: uuidv4(),
            element: <Cockpit />,
          },
          {
            id: uuidv4(),
            path: RoutePaths.DASHBOARDS_COCKPIT_PAGE,
            element: <Cockpit />,
          },
        ],
      },
      {
        id: uuidv4(),
        path: RoutePaths.SIGN_UP_PAGE,
        element: <SignUp />,
        suspense: true,
      },
      {
        id: uuidv4(),
        path: RoutePaths.ONBOARDING,
        element: <Onboarding />,
        protected: true,
        requireOrganization: true,
      },
      {
        id: uuidv4(),
        path: RoutePaths.INVITATION,
        element: <OnboardingOrganizationInvitation />,
        suspense: true,
      },
      {
        id: uuidv4(),
        path: RoutePaths.SETTINGS,
        element: <Settings />,
        protected: true,
        suspense: true,
        requireOrganization: true,
        children: [
          {
            id: uuidv4(),
            index: true,
            element: <General />,
            protected: true,
            requireOrganization: true,
          },
          {
            id: uuidv4(),
            path: RoutePaths.SETTINGS_GENERAL,
            element: <General />,
            protected: true,
            requireOrganization: true,
          },
          {
            id: uuidv4(),
            path: RoutePaths.SETTINGS_MEMBERS,
            element: <Members />,
            protected: true,
            requireOrganization: true,
          },
          {
            id: uuidv4(),
            path: RoutePaths.SETTINGS_USAGE,
            element: <Usage />,
            protected: true,
            requireOrganization: true,
          },
          {
            id: uuidv4(),
            path: RoutePaths.SETTINGS_CUSTOMIZATION,
            element: <Customization />,
            protected: true,
            requireOrganization: true,
          },
          {
            id: uuidv4(),
            path: RoutePaths.SETTINGS_WIDGET,
            element: <WidgetSettings />,
            protected: true,
            requireOrganization: true,
          },
          {
            id: uuidv4(),
            path: RoutePaths.SETTINGS_PROFILE,
            element: <Profile />,
            protected: true,
            requireOrganization: true,
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    path: RoutePaths.LOGIN,
    element: <Login />,
    onlyLoggedOut: true,
    requireOrganization: false,
    suspense: true,
  },
  {
    id: uuidv4(),
    path: RoutePaths.FINISH_SIGNIN,
    element: <FinishSignIn />,
    suspense: true,
    requireOrganization: false,
  },

  {
    id: uuidv4(),
    path: '*',
    element: <NotFound />,
  },
];
