import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Product } from '@dialog/search-contracts';

import { useGetProducts } from 'hooks/organization';

import { useOrganization } from '../../../components/OrganizationProvider/OrganizationProvider';
import { retrieveProductQuestionsFilter } from '../libs/filters';
import {
  Filter,
  FILTER_OPERATORS,
  FilterOption,
  ProductQuestionsFilters,
} from '../types';

interface ProductQuestionsFiltersContext {
  filters?: ProductQuestionsFilters;
  currentFilters?: Partial<Filter>[];
  products?: Product[];
  formatFilter: (value: string) => FilterOption | undefined;
  addCurrentTableFilters: (value: Partial<Filter>) => void;
  patchCurrentTableFilters: (index: number, value: Partial<Filter>) => void;
  deleteCurrentTableFilters: (index: number) => void;
  clearFilters: () => void;
}

export const ProductQuestionsFiltersContext =
  createContext<ProductQuestionsFiltersContext>({
    filters: undefined,
    products: undefined,
    currentFilters: undefined,
    formatFilter: () => undefined,
    addCurrentTableFilters: () => console.log('Not initialized'),
    patchCurrentTableFilters: () => console.log('Not initialized'),
    deleteCurrentTableFilters: () => console.log('Not initialized'),
    clearFilters: () => console.log('Not initialized'),
  });
export const useProductQuestionsFilters = (): ProductQuestionsFiltersContext =>
  useContext(ProductQuestionsFiltersContext);

export const ProductQuestionsFiltersProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}): JSX.Element => {
  const { organization } = useOrganization();
  const [currentFilters, setCurrentFilers] = useState<Partial<Filter>[]>([
    {
      column: 'placeholder',
      operator: FILTER_OPERATORS.IS_EQUAL,
    },
  ]);
  const [filters, setFilters] = useState<ProductQuestionsFilters>();

  const getProduct = useGetProducts({
    organizationSlug: organization.slug,
    onSuccess: data => {
      setFilters(retrieveProductQuestionsFilter(data.items));
    },
  });

  const formatFilter = useCallback(
    (value: string): FilterOption | undefined => {
      if (filters === undefined) return;

      let foundOption = filters.collections?.find(opt => opt.id === value);
      if (foundOption !== undefined) return foundOption;

      foundOption = filters.metadata?.find(opt => opt.id === value);
      if (foundOption !== undefined) return foundOption;

      foundOption = filters.productType?.find(opt => opt.id === value);
      if (foundOption !== undefined) return foundOption;

      return undefined;
    },
    [filters],
  );

  const clearFilters = useCallback(() => {
    setCurrentFilers([]);
  }, []);

  const addCurrentTableFilters = useCallback((value: Partial<Filter>) => {
    setCurrentFilers(prev => [...prev, value]);
  }, []);

  const deleteCurrentTableFilters = useCallback((index: number) => {
    setCurrentFilers(prev => prev.filter((_, idx) => index !== idx));
  }, []);

  const patchCurrentTableFilters = useCallback(
    (index: number, value: Partial<Filter>) => {
      const newFilters = [...currentFilters];
      newFilters[index] = { ...newFilters[index], ...value };
      setCurrentFilers(newFilters);
    },
    [currentFilters],
  );

  const contextValue = useMemo(() => {
    return {
      filters,
      products: getProduct.data?.items ?? [],
      currentFilters,
      formatFilter,
      addCurrentTableFilters,
      patchCurrentTableFilters,
      deleteCurrentTableFilters,
      clearFilters,
    };
  }, [
    currentFilters,
    filters,
    getProduct.data?.items,
    formatFilter,
    addCurrentTableFilters,
    patchCurrentTableFilters,
    deleteCurrentTableFilters,
    clearFilters,
  ]);

  return (
    <ProductQuestionsFiltersContext.Provider value={contextValue}>
      {children}
    </ProductQuestionsFiltersContext.Provider>
  );
};
