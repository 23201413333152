import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { QueryObserverResult } from 'react-query';

import { ShopLocale } from '@dialog/organization-contracts';
import { ProductPageQuestionsList } from '@dialog/search-contracts';

import { useGetProductPageQuestions } from 'hooks/products';

import { useOrganization } from '../../../components/OrganizationProvider/OrganizationProvider';
import { filterProductPageQuestion } from '../libs/filters';
import { enrichProductPageQuestion } from '../libs/format';
import {
  PaginationAction,
  PREV_ACTION,
  ProductQuestionsBanner,
  ProductQuestions as ProductQuestionsI,
} from '../types';
import { useProductQuestionsFilters } from './ProductQuestionsFiltersProvider';

interface ProductQuestionsContext {
  selectedQuestionsIds: string[];
  setSelectedQuestionsIds: (productQuestionsIds: string[]) => void;
  currentLocale?: ShopLocale;
  setCurrentLocale: (locale: ShopLocale) => void;
  banners: ProductQuestionsBanner[];
  setBanners: (banners: ProductQuestionsBanner[]) => void;
  isLoadingProductPageQuestions: boolean;
  productPageQuestions: ProductQuestionsI[];
  filteredProductPageQuestions: ProductQuestionsI[];
  paginatedProductPageQuestions: ProductQuestionsI[];
  enrichedProductPageQuestions: ProductQuestionsI[];
  setProductQuestions: Dispatch<SetStateAction<ProductQuestionsI[]>>;
  handleChangePagination: (action: PaginationAction) => void;
  refetch: () => Promise<QueryObserverResult<ProductPageQuestionsList>>;
  currentPage: number;
}

export const ProductQuestionsContext = createContext<ProductQuestionsContext>({
  selectedQuestionsIds: [],
  setSelectedQuestionsIds: () =>
    console.error('Product questions context is not well initialized'),
  currentLocale: undefined,
  setCurrentLocale: () =>
    console.error('Product questions context is not well initialized'),
  banners: [],
  isLoadingProductPageQuestions: false,
  productPageQuestions: [],
  filteredProductPageQuestions: [],
  enrichedProductPageQuestions: [],
  paginatedProductPageQuestions: [],
  setProductQuestions: () => {
    console.error('Product questions context is not well initialized');
  },
  setBanners: () =>
    console.error('Product questions context is not well initialized'),
  handleChangePagination: () => console.error('Not initialized'),
  refetch: () =>
    Promise.resolve({} as QueryObserverResult<ProductPageQuestionsList>),
  currentPage: 0,
});
export const useProductQuestions = (): ProductQuestionsContext =>
  useContext(ProductQuestionsContext);

export const DEFAULT_PAGE_SIZE = 100;

export const ProductQuestionsProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}): JSX.Element => {
  const { organization } = useOrganization();
  const [currentLocale, setCurrentLocale] = useState(
    organization.shopLocales?.find(locale => locale.primary),
  );
  const { products, currentFilters } = useProductQuestionsFilters();
  const [productPageQuestions, setProductPageQuestions] = useState<
    ProductQuestionsI[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleChangePagination = useCallback(
    (action: PaginationAction) => {
      const newPage =
        action === PREV_ACTION ? currentPage - 1 : currentPage + 1;
      setCurrentPage(newPage);
    },
    [currentPage],
  );

  const enrichedProductPageQuestions = useMemo(() => {
    return enrichProductPageQuestion(products, productPageQuestions);
  }, [products, productPageQuestions]);

  const filteredProductPageQuestions = useMemo(() => {
    return filterProductPageQuestion(
      enrichedProductPageQuestions,
      currentFilters,
    );
  }, [enrichedProductPageQuestions, currentFilters]);

  const paginatedProductPageQuestions = useMemo(() => {
    return filteredProductPageQuestions.slice(
      currentPage * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE + DEFAULT_PAGE_SIZE,
    );
  }, [filteredProductPageQuestions, currentPage]);

  const { isLoading, refetch } = useGetProductPageQuestions({
    organizationSlug: organization.slug,
    locale: currentLocale?.locale ?? 'en',
    onSuccess: data => {
      const formattedProductPageQuestions = data.items.map(
        productPageQuestion => ({
          productName: productPageQuestion.productTitle,
          productId: productPageQuestion.productId,
          previewQuestions:
            productPageQuestion.previewQuestions ??
            productPageQuestion.questions,
          questions: productPageQuestion.questions ?? [],
        }),
      );
      setProductPageQuestions(formattedProductPageQuestions);
    },
  });

  const [selectedQuestionsIds, setSelectedQuestionsIds] = useState<string[]>(
    [],
  );
  const [banners, setBanners] = useState<ProductQuestionsBanner[]>([]);

  const contextValue = useMemo(() => {
    return {
      selectedQuestionsIds,
      setSelectedQuestionsIds,
      currentLocale,
      setCurrentLocale,
      banners,
      setBanners,
      productPageQuestions: paginatedProductPageQuestions,
      filteredProductPageQuestions,
      enrichedProductPageQuestions,
      setProductQuestions: setProductPageQuestions,
      isLoadingProductPageQuestions: isLoading,
      handleChangePagination,
      paginatedProductPageQuestions,
      currentPage,
      refetch,
    };
  }, [
    selectedQuestionsIds,
    filteredProductPageQuestions,
    paginatedProductPageQuestions,
    enrichedProductPageQuestions,
    setSelectedQuestionsIds,
    isLoading,
    currentLocale,
    setCurrentLocale,
    banners,
    setBanners,
    handleChangePagination,
    currentPage,
    refetch,
  ]);

  return (
    <ProductQuestionsContext.Provider value={contextValue}>
      {children}
    </ProductQuestionsContext.Provider>
  );
};
