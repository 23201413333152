import { useQuery, UseQueryResult } from 'react-query';

import { ProductsResults } from '@dialog/organization-contracts';
import { Product } from '@dialog/search-contracts';

import { getProducts } from 'services/fetch';

import { getGenerateProductsKey } from './keys';

export interface IUseGetProducts {
  organizationSlug: string;
  onSuccess?: (data: ProductsResults) => void;
  onError?: () => void;
}
export type IUseGetProductsResponse = UseQueryResult<{
  items: Product[];
}>;
export const useGetProducts = ({
  organizationSlug,
  onSuccess,
  onError,
}: IUseGetProducts): IUseGetProductsResponse => {
  const key = getGenerateProductsKey({ organizationSlug });

  const onMutationSuccess = (data: ProductsResults) => {
    if (onSuccess) {
      onSuccess(data);
    }
  };

  const onMutationError = () => {
    if (onError) {
      onError();
    }
  };

  return useQuery(key, async () => getProducts(organizationSlug), {
    onSuccess: onMutationSuccess,
    onError: onMutationError,
    staleTime: 5 * 60 * 1000,
  });
};
