/* eslint-disable max-lines */
export const enUSMessages = {
  'user-not-found': 'User not found',
  'unknown-error': 'Something unexpected happened',
  'invalid-email': 'This is not a valid email',
  'invalid-name': 'Name should not be blank',
  'invalid-position': 'Position should not be blank',
  '==': 'equals to',
  daysAgo: 'days ago',
  contains: 'contains',
  doesNotContain: 'does not contain',
  '>': 'is greater than',
  'REL>': 'more than',
  'REL<': 'less than',
  '>=': 'is greater or equals',
  isTrue: 'is true',
  isFalse: 'is false',
  on: 'on',
  isSet: 'is set',
  is: 'is',
  isNot: 'is not',
  isNotSet: 'is not set',
  '<': 'is less than',
  '<=': 'is lesser or equals',
  Wallets: 'wallets',
  before: 'before',
  isBefore: 'is before',
  after: 'after',
  isAfter: 'is after',
  balanceIsGreater: 'balance is greater',
  balanceGT: 'balance is greater',
  balanceIsGreaterOrEquals: 'balance is greater or equals',
  balanceGTE: 'balance is greater or equals',
  balanceIsLessOrEquals: 'balance is less or equals',
  balanceLTE: 'balance is less or equals',
  balanceIsLess: 'balance is less than',
  balanceLT: 'balance is less than',
  balanceEquals: 'balance equals',
  arrayContains: 'contains',
  arrayNotContains: 'does not contain',
  arrayNotEmpty: 'has some',
  arrayEmpty: 'has none',
  hasWithSuffix: 'has {suffix}',
  hasNotWithSuffix: 'has not {suffix}',
  has: 'has any',
  hasNot: 'has not any',
  hasCollection: 'has any',
  wallets: 'wallets',
  back: 'Back',
  UsernameExistsException: 'Email already exists',
  invalidAddress: 'Please enter a valid contract address',
  searchAttributes: 'Search attributes',
  quantity: 'quantity',
  contactSupport: 'Contact support',
  copy: 'Copy',
  copied: 'Copied!',
  poweredBy: 'Powered by',
  playground: {
    title: 'Playground',
    languageSelection:
      'Select the language in which you want to test the assistant',
    languageSelectionPlaceholder: 'Select language',
    productSelection:
      'Choose a product to see how the assistant answers questions about it. Leave empty to test without the context of a product page',
    productSelectionPlaceholder: 'Search products',
  },
  dashboards: {
    title: 'Dashboards',
    sidebarTitle: 'Metrics',
    cockpit: {
      title: 'Cockpit',
      description: "See all your Dialog's metrics",
      overview: 'Overview',
      periods: {
        lastMonth: 'Last month',
        lastWeek: 'Last week',
        lastDay: 'Last day',
        lastYear: 'Last year',
      },
      metrics: {
        revenueGenerated: 'Revenue generated',
        addToCartRate: 'Add-to-cart rate',
        conversionRate: 'Conversion rate',
        engagementRate: 'Engagement rate',
        totalConversation: 'Total conversation',
      },
      chart: {
        totalConversation: 'Total conversations',
        ytd: 'YTD',
        allTime: 'All time',
      },
    },
  },
  importProgress: {
    progress: 'Indexing your data, it can take a few minutes. Import progress:',
    success: 'Success! Import progress:',
    started: 'Your import has started. Import progress:',
    starting: 'Your import is starting. Import progress:',
    waitingForShopify: 'Waiting for Shopify response. Import progress:',
    readyToIndex: 'Waiting for Shopify response. Import progress:',
    creatingCatalogDatabase: 'Creating catalog database. Import progress:',
  },
  navbar: {
    search: 'Search address',
    labelUserSettings: 'User settings',
    logOut: 'Log out',
    workspaceSettings: 'Organization settings',
    dialogGuide: 'Dialog guide',
    news: "What's new",
  },
  userProfile: {
    save: 'Save',
    newEmail: 'New email',
    name: 'Name',
    profile: 'Profile',
    email: 'Email',
    changeEmail: 'Change email',
    changeEmailError: 'You cannot change your email yet',
  },
  columns: {
    title: 'Columns',
    add: 'Add column',
    apply: 'Apply',
    listingDescription: 'Add or remove columns from your list',
    searchTokenPlaceholder: 'Search token...',
    emptyListDataTitle: 'No results found',
    emptyListDataDescription:
      'We have not found any results matching your search',
    emptyListDataCTA: 'Clear search',
    idleListDataTitle: 'Empty search',
    idleListDataDescription: 'Search something to find some results',
  },
  menu: {
    profile: 'Your profile',
    logOut: 'Log out',
  },
  settings: {
    title: 'Settings',
    changeName: 'You can not change the name of your organization yet',
    organization: 'Organization',
    integrations: {
      title: 'Integrations',
      description: 'Connect your existing tools with Dialog.',
      enabled: 'Enabled',
      comingSoon: 'Coming soon',
      cards: {
        empty: {
          content: 'More integration soon',
        },
      },
      support: {
        title: 'Propose a new integration',
        description:
          'We are constantly looking for integrating with external services to help you grow your users.',
      },
    },
    'widget-settings': {
      title: 'Dialog widget settings',
      poweredBy: 'Powered by Dialog',
      shoppingIconTitle: 'Add to cart icon',
      shoppingIconDescription:
        'Select which  add to cart icon you want to display in the assistant',
      bookmarkLogo: 'Your assistant logo',
      maxBookmarkSize:
        'Your image must be less than 5MB. Accepted formats: PNG, JPG, JPEG',
      uploadLogo: 'Upload your logo',
      displayPoweredBy: 'Display powered by Dialog?',
      description: 'Customize the look and feel of your Dialog widget',
      save: 'Save changes',
      warning: 'You cannot modify your widget settings in the free plan',
      assistantName: 'Your assistant name',
      dialogInputPlaceholder: 'Dialog input placeholder',
      assistantDescription: 'Your assistant description',
      warningDescription:
        'Upgrade to a paid plan to modify your widget settings',
    },
    'ai-expert': {
      title: 'AI expert setup',
      description: 'Customize your AI expert',
      business: 'Describe your business as clearly and concisely as possible',
      expertise: 'Enter the area of expertise your AI needs to master',
      save: 'Save changes',
      warning: 'You cannot upload more than 2 files',
      warningDescription: 'Upgrade to a paid plan to upload more files',
      uploadFiles:
        'Upload additional files that would add knowledge to your assistant',
    },
    usage: {
      usage: {
        title: 'Usage',
        description: 'Current usage informations',
        progressBarDescription:
          '{currentValue} of {maxValue} conversations used',
        additionalProgressBarDescription:
          '{currentValue} additional conversations used ({value}$)',
      },
      title: 'Plan & Usage',
      description: 'Plan & Usage informations',
      plan: 'Plan',
      plans: {
        upgrade: 'Upgrade plan',
        change: 'Change plan',
        free: 'Free',
        scale: 'Scale',
        enterprise: 'Enterprise',
        features: {
          includedConversation: '{value} included conversations per month',
          pricePerAdditionalConversation:
            '{value}$ per additional conversation',
          pricePerAdditionalConversationBasic: 'Blocked',
          aiModelBasic: 'Basic',
          aiModelAdvanced: 'Advanced',
          aiModelEnterprise: 'Advanced or Custom',
          uploadDocuments: '{value} upload of data sources documents',
          customizeTriggers: 'Customize triggers',
          accessHistory: 'Access to conversations historic',
          customerSuccessBasic: 'Emails and live chat',
          customerSuccessAdvanced: 'Emails, live chat and on-call',
          customerSuccessEnterprise: 'Dedicated slack channel',
          customLogo: 'Custom logo',
          customWording: 'Custom wording',
          customDataSource: 'Custom data sources',
          customConversation: 'Customize conversation starter',
          analyticsReports: 'Analytics reports',
          customCode: 'Custom code to personnalize your AI',
        },
      },
    },
    members: {
      removeMember: 'Remove from organization',
      title: 'Members',
      description: 'Manage your team members',
      inviteButtonLabel: 'Invite member',
      searchbarPlaceholder: 'Search by name...',
      inviteModal: {
        inputPlaceholder: 'Email...',
        title: 'Invite members',
        description: 'Invite your team members to join your organization',
        applyButton: 'Invite',
        cancelButton: 'Cancel',
        invalidEmail: 'Enter a valid email',
      },
      removeMemberModal: {
        title: 'Remove {name} from the organization?',
        description:
          "This user will no longer have access to your organization and it's content",
        applyButton: 'Remove',
        cancelButton: 'Cancel',
      },
    },
    general: {
      title: 'General',
      description: "Manage your organization's informations",
      inputOrganizationName: "Organization's name",
      inputAvatarName: "Organization's picture",
      inputAvatarDescription:
        "Your organization's image will be visible in app messages",
      inputAvatarButton: 'Upload image (png, jpg, jpeg, Max 3MB)',
      inputCountryName: 'Country',
      buttonSave: 'Save changes',
      inputOrganizationDomainName: 'Domain name of your organization',
      supportTitle: 'Contact us to change your organization name',
      supportDescription:
        'You are not allowed to change your organization name for security reasons. Please contact our support team if you want to change your organization name.',
    },
    setup: {
      title: 'Setup',
      description:
        'Setup Dialog on your platform to identify connected wallets on your website',
      installScript:
        'Install the following code to start collecting wallet addresses',
      publicApiKey: 'Public API key',
      privateApiKey: 'Private API key',
    },
    profile: {
      title: 'Profile',
      description: 'Manage your personal information',
      firstName: 'First name',
      lastName: 'Last name',
      position: 'Position',
      positionDescription: 'Users will see your position in app messages',
      picture: 'Picture',
      lastNameError: 'Enter a last name',
      firstNameError: 'Enter a first name',
      inputAvatarButton: 'Upload image (png, jpg, jpeg, Max 3MB)',
      avatarDescription: 'Users will see your avatar image in app messages',
      email: 'Email',
      supportTitle: 'Contact us to change your email',
      supportDescription:
        'You are not allowed to change your email for security reasons. Please contact our support team if you want to change your email address.',
      save: 'Save changes',
    },
    name: 'Name',
    account: 'Account',
    installCode: 'Install code',
    publicApiKey: 'Public Api Key',
    privateApiKey: 'Private Api Key',
    install:
      'Install the following code to collect wallet addresses when users connect to your site',
  },
  filters: {
    andCap: 'AND',
    orCap: 'OR',
    customSegment: 'You will soon be able to create custom segments',
    isAfter: 'is after',
    isBefore: 'is before',
    lesserThan: 'is less than',
    greaterThan: 'is greater than',
    equals: 'equals',
    showWalletsMatching: 'Show wallets matching',
    concernedChain: 'Concerned chain(s)',
    allChains: 'All chains',
    allConditions: 'All filters',
    anyCondition: 'Any filters',
    addGroup: 'Add group filter',
    add: 'Add filter',
    edit: 'Edit filter',
    apply: 'Apply',
    title: 'Filters',
    filterCountWallets: 'Showing {count} wallets',
    searchNfts: 'Search for collection or type address...',
    searchTokens: 'Search for token or type address...',
    noResultsTitle: {
      nftCollections: 'None of your users have this collection yet',
      tokens: 'None of your users have this token yet',
    },
    noResultsSubtitle: {
      nftCollections:
        'Search using the collection smart contract to display unlisted collections',
      tokens:
        'Search using the token smart contract to display unlisted tokens',
    },
    rootLevelOnly:
      'This filter can only be used at root level, not in sub-groups',
    disabled: 'You cannot select this filter',
    campaignSelect: 'Select campaign...',
    'event.CAMPAIGN_SENT.suffix': 'been sent',
    'event.CAMPAIGN_SEEN.suffix': 'viewed',
    'event.CAMPAIGN_CLICKED.suffix': 'clicked',
  },
  configureShopping: {
    title: 'Configure your Shopify',
    description: 'Import your products and inventory',
    product: 'Product',
    inventory: 'Inventory',
    informationContainer: {
      titleProduct: 'CSV Product template',
      descriptionProduct:
        'To ensure successful import, it is important that your products.csv file follows Product Shopify template',
      descriptionUploadArea: 'Up to 5MB, .CSV format',
      titleInventory: 'CSV Inventory template',
      descriptionInventory:
        'To ensure successful import, it is important that your inventory.csv file follows Product Shopify template',
      downloadButton: 'Download template',
    },
    successMessage: '{value} has been correctly imported!',
    failedMessage:
      '{value} has not been correctly imported! Drop again your document.',
  },
  assistant: {
    title: 'Assistant POC',
    description: '',
  },
  productQuestions: {
    saveAnswer: 'Use answer',
    addAnswer: 'Add a custom answer',
    addAnswerDescription:
      'You will need to save on the main page to validate your changes',
    addAnswerTitle: 'Add a custom answer',
    deleteAnswer: 'Delete answer',
    displayUnsaved: 'Show unsaved',
    showAll: 'Show all',
    clearUnsaved: 'Clear unsaved',
    operators: {
      isNotEmpty: 'is not empty',
      isEmpty: 'is empty',
      isEqual: 'is equal to',
      anyOf: 'any of',
      noneOf: 'none of',
      allOf: 'all of',
      contains: 'contains',
      doesNotContain: 'does not contain',
      startWith: 'starts with',
      endsWith: 'ends with',
    },
    banners: {
      actions: {
        generate: 'Generating',
        translate: 'Translating',
      },
      generateTranslate: '{action} product page questions ',
    },
    metaKeyPlaceholder: 'key',
    stringPlaceholder: 'value',
    filterByField: 'Filter by field...',
    clearFilters: 'Clear filters',
    searchForProduct: 'Search for a product name...',
    title: 'Product questions edition',
    default: ' (default)',
    filters: {
      collections: 'Collections',
      metafields: 'Metafields',
      productTypes: 'Product Types',
    },
    description:
      '{count} {count, plural, =0{product} one {product} other {products}}',
    generationQuestionButtonTooltip: 'Generate a question',
    actionsButtons: {
      duplicateProductQuestions: 'Duplicate product questions',
      filters: 'Filters',
      translateFromDefaultLanguage: 'Translate from default language',
      generateQuestions: 'Generate questions',
      duplicate: 'Duplicate',
      save: 'Save',
    },
    duplicateModal: {
      label: 'Duplicate from',
      placeholder: 'Choose the source question to duplicate',
    },
    apiKeyModal: {
      title: 'Welcome on board! 🎉',
      info: 'You can also find your API Key in settings',
      applyButton: 'Done!',
      description:
        'Now that you have complete onboarding, we advise you to copy and keep your API key separetly.',
    },
    table: {
      unsavedChanges:
        '"•" before a product name indicates that you have unsaved changes',
      headers: {
        productName: 'Product name',
        productId: 'Product Id',
        question1: 'Question 1',
        question2: 'Question 2',
        question3: 'Question 3',
      },
    },
    actionsModals: {
      productQuestionsDuplicateQuestionsModalTitle:
        'Duplicate product questions',
      productQuestionsDuplicateQuestionsModalDescription:
        'Every product selected will copy questions from the product below',
      productQuestionsSaveModalTitle: 'Saving product questions',
      productQuestionsSaveModalDescription:
        'Are you sure you want to save {count} {count, plural, =0{product question} one {product question} other {product questions}}?',
      productQuestionsGenerateQuestionsModalTitle: 'Generate questions',
      productQuestionsGenerateQuestionsModalDescription: `Are you sure you want to generate with AI questions for {count} {count, plural, =0{product} one {product} other {products}}?
        \nWhen you will save the questions, it will also override any pre-written answers associated to these questions.`,
      productQuestionsTranslateQuestionsModalTitle:
        'Translate questions from default language',
      productQuestionsTranslateQuestionsModalDescription:
        'Are you sure you want to translate from your default language {language} questions for {count} {count, plural, =0{product} one {product} other {products}}?',
    },
  },
  home: {
    banners: {
      inProgress: 'In progress',
    },
    title: 'Home',
    columns: 'Columns',
    filters: 'Filters',
    cancelModal: {
      title: 'Leave without saving?',
      description: 'When leaving the page you will lose your unsaved changes.',
      cancelButton: 'Stay',
      applyButton: 'Leave anyway',
    },
    saveColumns: {
      button: 'Save columns',
      tooltip:
        'These columns will be set as your default columns for all new segments',
    },
  },
  'sign-up': {
    success: 'You successfully signed up!',
  },
  auth: {
    agreementMessage: 'I agree to the {terms} and {privacy}',
    privacy: 'Privacy Policy',
    terms: 'Terms of Service',
    login: 'Log in',
    signUp: 'Sign up',
    continue: 'Continue',
  },
  login: {
    magicLinkTitle: 'Magic Link sent',
    magicLinkDescription:
      'Check your inbox, we’ve sent you a magic login link at {email}',
    backToLoginView: 'Back to log in',
    emailError: 'Enter a valid email address',
    noAccount: "Don't have an account? {signUp}",
    cta: 'Log in',
    emailPlaceholder: 'Enter your email...',
    title: 'Welcome to Dialog',
    description:
      'Enter the email linked to your Shopify Account and we’ll send you a magic log in link',
    'sign-up': 'Sign up',
    name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    position: 'Position',
    'login-success':
      'Check your emails and click on your magic link to authenticate',
    email: 'Email',
    'no-account': 'No account yet?',
  },
  onboardingOrganizationInvitation: {
    title: 'You have been invited to join {organizationName} on Dialog',
    description: 'We need to know a fee things to set up your account',
    email: 'Email',
    firstName: 'First name',
    firstNamePlaceholder: 'Enter your first name',
    firstNameError: 'First name is a required field',
    lastName: 'Last name',
    lastNamePlaceholder: 'Enter your last name',
    lastNameError: 'Last name is a required field',
    alreadyAccount: 'Already have an account? {login}',
    login: 'Log in',
    cta: 'Sign up',
  },
  signUp: {
    title: 'Sign up',
    description: 'We need to know a fee things to set up your account',
    backToLoginView: 'Back to log in',
    magicLinkTitle: 'Magic Link sent',
    magicLinkDescription:
      'Check your inbox, we’ve sent you a magic login link at {email}',
    emailPlaceholder: 'Enter your email...',
    email: 'Email',
    emailError: 'Enter a valid email address',
    cta: 'Sign up',
    alreadyAccount: 'Already have an account? {signUp}',
    login: 'Log in',
    firstName: 'First name',
    firstNamePlaceholder: 'Enter your first name',
    firstNameError: 'First name is a required field',
    lastName: 'Last name',
    lastNamePlaceholder: 'Enter your last name',
    lastNameError: 'Last name is a required field',
    position: 'Position',
    positionPlaceholder: 'Enter your position',
    positionError: 'Position is a required field',
  },
  onboarding: {
    createOrganizationTitle: 'Store creation',
    createOrganizationDescription:
      'Choose a name for your store in Dialog. Usually it is your company name.',
    createOrganization: 'Organization',
    installShopifyApp: 'Installation',
    installShopifyAppTitle: 'Install Dialog Shopify app',
    installShopifyAppDescription:
      'Start by installing Dialog Shopify app from the Shopify app store',
    createOrganizationPlaceholder: 'Your store/company name',
    createOrganizationButton: 'Create organization',
    shopifyDomain: 'Your Shopify domain',
    step: 'Step {currentPage} {maxPage}',
    previous: 'Previous',
    maxPage: 'of {maxPage}',
    next: 'Next',
    choosePlan: {
      title: 'You did not choose a billing plan yet.',
      content:
        'Choose your plan in your Shopify admin in the {dialogApplicationLink}',
    },
    getStartedStep: {
      optional: '*optional',
      getStarted: 'Get started',
      title: "Welcome to Dialog, let's set up your AI expert",
      description:
        'Here are the steps to configure your AI expert in just a few minutes.',
      category: 'Get started',
      configureMetaFields: 'Configure metafields',
      yourBusiness: 'Describe your business',
      areaOfExpertise: 'Your AI area of expertise',
      documentUpload: 'Documents upload',
      setUpWorkspace: 'Set up your workspace',
    },
    configureMetafieldsStep: {
      title: 'Please select required metafields',
      description:
        'Select the product metafields that will help your AI assistant better understand the products your store sells. Avoid including the same information twice (for instance the global.title_tag metafield is often redundant with the title field of a product).',
      category: 'Configure metafields',
    },
    businessStep: {
      title:
        'Describe your business and how you want your assistant to interact as clearly and concisely as possible',
      description:
        'This text will be used by the assistant to understand your business and how you want it to interact with your customers.',
      category: 'Your business',
      textAreaPlaceholder:
        'You are a sales assistant for Shopx, a retail snowboard brand with an online store. Shopx sells snowboards with and without bindings, boots and snowboard accessories. You engage with customers in a very relaxed way and are always kind and friendly with them',
    },
    businessSkillsStep: {
      title: 'Enter the area of expertise your AI needs to master',
      description:
        'The skills you enter will help our AI to refine these answers for your customers. For instance, for a cosmetic store, it could be Face treatments, Body treatments, Hair treatments and Makeup.',
      category: 'Areas of expertise',
      textAreaPlaceholder: 'Separate keywords pressing enter',
      textAreaDescription: 'Minimum {min} and maximum {max} skills',
    },
    uploadFilesStep: {
      uploadFilesWarning: 'You cannot upload more than 2 files',
      uploadFilesWarningDescription:
        'Upgrade to a paid plan to upload more files',
      title: 'Please, upload additional files that could help us',
      description:
        'Send us any file that could help us understand your business, such as FAQs, Manifesto, Product Guide, etc.',
      category: 'Upload files',
      fileUploadTitle: 'Drop your document here, or click to browse',
      fileUploadDescription: 'TXT, DOCX, PDF supported, up to 50MB.',
      optional: '*Optional',
    },
    configureWorkspaceStep: {
      title: 'Great we can now configure your workspace',
      description: 'We will need your logo and a name for your store.',
      category: 'Set up workspace',
      uploadLogoTitle: 'Upload your logo',
      uploadLogoDescription: 'JPG, PNG, PDF supported, up to 5MB.',
      storeName: 'Store name',
      continue: 'Complete',
    },
    titleForm: 'Create an organization',
    script: 'Script',
    descriptionForm:
      'The information of your organization will be visible to your users through your messages',
    organizationNameLabel: 'Organization name',
    organizationPlaceholder: 'Enter your company name...',
    unavailableOrganizationName: 'This organization name already exists',
    requiredOrganizationName: 'Organization name is required',
    organizationPicture: "Organization's picture",
    gettingStartedTitle: "Paste Dialog's script in your code's site",
    gettingStartedTitleEcommerce: "You're all set!",
    gettingStartedDescription:
      "This code will send your customer's addresses to Dialog once a new user connect his wallet",
    gettingStartedDescriptionEcommerce:
      'Click on continue to start creating your AI assistant',
    continueCta: 'Continue',
    'connect-wallet': 'Connect a company wallet',
    'organization-exists': 'An organization with this name already exists',
    'connect-wallet-description':
      'This will be your identity to chat with your users. We recommend to connect a wallet with an ENS associated so users can verify',
    'create-organization': 'Create an organization',
    code: "This code will send your customer's addresses to Dialog once a new user connect his wallet",
    continue: 'Continue',
    skip: 'Skip this step',
    setup: 'Setup',
    connect: 'Connect Metamask',
    organization: 'Organization',
    done: "It's done!",
    chat: 'Chat',
    'set-up': 'Set up',
    'paste-script': "Paste this script in your code's site",
    'code-explaination':
      "This code will send your customer's addresses to CRM3 once a new user connect his wallet",
  },
  buttons: {
    duplicate: 'Duplicate',
    translate: 'Translate',
    generate: 'Generate',
    cancel: 'Cancel',
    saveLater: 'Save for later',
    saveAsDraft: 'Save as draft',
    live: 'Set it live',
    scheduleMessage: 'Schedule message',
    continue: 'Continue',
    edit: 'Edit',
    save: 'Save',
    next: 'Next',
    back: 'Back',
    delete: 'Delete',
    update: 'Update',
    import: 'Import',
    add: 'Add',
    disable: 'Disable',
  },
  placeholders: {
    enterTitle: 'Enter a title',
    deleteCaps: 'DELETE',
  },
  toasts: {
    success: 'Success',
    error: 'Error',
    info: 'Information',
    alert: 'Alert',
  },
  hooks: {
    aggregation: {
      deleteSelectedWallets: {
        titleSuccess: 'Current wallets deleted',
        descriptionSuccess:
          'All current wallets from this segment has been deleted successfully',
        descriptionError: 'Oups! An error occured!',
      },
    },
    shared: {
      fileUploadDescriptionSizeError: 'Picture should be under 3MB!',
      documentUploadDescriptionSizeError: 'Document should be under 50MB!',
    },
    organizationIntegration: {
      enableSuccessDescription: 'Segment has been enabled!',
      disabledSuccessDescription: 'Segment has been disabled!',
      errorDescription: 'Oups! An error occured!',
    },
    wallets: {
      importValidateCsvDefaultErrorDescription:
        'Something wrong happened during validation',
      fileSizeLimitTitle: 'Row limit exceeded !',
      fileSizeLimitDescription:
        'You have exceeded the row limit authorized < {limit}',
      importValidatedCsvTitleSuccess: 'Importation is in progress...',
      importValidatedCsvDescriptionSuccess:
        'You will receive a confirmation email upon completion of the import',
      exportWalletsTitleCsv: 'Export is in progress...',
      exportWalletsDescriptionCsv:
        'CSV will be available in your download folder in a few seconds.',
      updateOrganizationWalletData: {
        descriptionError: 'Oups! An error occured!',
        wrongEmailError: 'Wrong email format',
      },
    },
    organization: {
      updateSuccess: 'Organization has been successfully updated',
      updateError: 'Oups ! An error occured during organization update',
      updateOrganizationPictureDescriptionError:
        'Oups! An error occured during picture upload 🙁',
      updateOrganizationPictureDescriptionFormatError:
        'Picture should be in a .png, .jpg or .jpeg format!',
      updateOrganizationPictureDescriptionSizeError:
        'Picture should be under 3MB!',
      requestPlanUpgradeSuccessTitle: 'Thank you !',
      requestPlanUpgradeSuccessDescription:
        'Our team will contact you to discuss the best plan for you.',
      requestPlanUpgradeError: 'Oups! An error occurred 🙁.',
    },
    auth: {
      updateUserPictureDescriptionError:
        'Oups! An error occured during picture upload 🙁',
      updateUserPictureDescriptionFormatError:
        'Picture should be in a .png, .jpg or .jpeg format!',
      updateUserPictureDescriptionSizeError: 'Picture should be under 3MB!',
      removeUserFromOrgTitleSuccess:
        '{name} has been removed from your organization',
      removeUserFromOrgDescriptionSuccess:
        'This user has no longer access to your organization',
      removeUserFromOrgFailed: 'Oups! An error occured 🙁',
      loginFailed: 'Oups! An error occured during connection, wrong email 🙁?',
      registerFailed: 'Oups! An error occured during sign up 🙁!',
      unknownUser: `User not found 🙁, you may verify your email!{lineBreak}If you created your account via Shopify, use the email address linked to your Shopify account.`,
      UsernameExistsException: 'Email already used 🙁!',
      inviteUserTitle: 'Invitation has been sent',
      inviteUserDescription: 'An invitation link has been sent to {email}',
      inviteUserDescriptionFailed:
        'Oups! An error occured during invitation 🙁',
    },
    messages: {
      createSuccess: 'Message has been created 🎉!',
      createFailed: 'Oups! An error occurred during message creation 🙁.',
      updateSuccess: 'Message has been updated 🎉!',
      updateFailed: 'Oups! An error occurred during message update 🙁. ',
      updateStatusSuccess: 'Message status has been changed 🎉!',
      updateStatusFailed: 'Oups! An error occurred 🙁.',
      archiveTitle: 'Message deleted',
      archiveSuccess: 'The message has been deleted successfully 🎉!',
      archiveFailed: 'Oups! An error occurred during message deletion 🙁.',
      templateCreateSuccess: 'Template has been created 🎉!',
      templateCreateFailed:
        'Oups! An error occurred during template creation 🙁.',
      invalidScheduling: 'Scheduled message has invalid start or/and end date!',
    },
    segments: {
      uniqueErrorDescription:
        'Segment name should be unique, {name} is already used.',
      defaultErrorDescription:
        'Oups! An error occured during segment creation 🙁',
      deleteTitleSuccess: 'Segment deleted',
      deleteSuccess: 'The segment has been deleted successfully 🎉!',
      deleteFailed: 'Oups! An error occured during message deletion 🙁.',
    },
  },
  shared: {
    search: 'Search...',
    emptyListItem: {
      title: 'No results found',
      description: 'We have not found any results matching your search',
      cta: 'Clear search',
    },
  },
  comingSoonScreen: {
    title: 'Coming soon !',
    description:
      'Sorry for the inconvenience, this page is under construction.',
  },
  errors: {
    invalidEmail: 'Wrong email format (name@provider.com)',
    userIsNotAuthorizedToPerformAction:
      'You do not have the right access to perform this action',
    userHasAlreadyOrganization: 'User has already an organization',
    userIsAlreadyInOrganization: 'User is already in organization',
    userAccountAlreadyExist: 'User already exist',
    internalError: 'Something unexpected happened',
    userNotExist: "User doesn't exist",
    notAllowToJoin: 'User is not allowed to join this organization',
    somethingWentWrong: 'Something went wrong',
    splashScreen: {
      title: 'Oops! Something went wrong',
      description:
        'Sorry for the inconvenience, you may reload the page. If the error persists please',
      cta: 'Reload',
    },
    splashScreenWithCustomError: {
      title: 'Oops! Something went wrong',
      description: 'Sorry for the inconvenience, If the error persists please',
      cta: 'Reload',
      cause: 'Cause: {cause}',
    },
  },
};
export default enUSMessages;
