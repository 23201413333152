import { GenerateOrTranslateProductPageQuestionsContent } from '@dialog/notification-contracts';
import { Product, ProductQuestion } from '@dialog/search-contracts';

export interface ProductQuestions {
  previewQuestions?: ProductQuestion[];
  productName: string;
  productId: string;
  collections?: Product['collections'];
  metadata?: Product['metadata'];
  productType?: Product['productType'];
  questions: ProductQuestion[];
}

export interface ProductQuestionsBanner
  extends GenerateOrTranslateProductPageQuestionsContent {
  title: string;
  index: number;
}

export const PREV_ACTION = 'prev' as const;
export const NEXT_ACTION = 'next' as const;
export type PaginationAction = typeof PREV_ACTION | typeof NEXT_ACTION;
export interface ActionPaginationDetails {
  action: PaginationAction;
  keys: { pk: string; sk: string };
}
export interface PaginationInfos {
  next?: {
    PK: string;
    SK: string;
  };
  prev?: {
    PK: string;
    SK: string;
  };
}

export enum FILTER_OPERATORS {
  IS_EQUAL = 'isEqual',
  ANY_OF = 'anyOf',
  NONE_OF = 'noneOf',
  ALL_OF = 'allOf',
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'doesNotContain',
  START_WITH = 'startWith',
  ENDS_WITH = 'endsWith',
  IS_EMPTY = 'isEmpty',
  IS_NOT_EMPTY = 'isNotEmpty',
}

export type TypeFilter = 'multiSelect' | 'string' | 'keyValue' | 'field';

export interface Filter {
  value: string | string[];
  column: keyof ProductQuestionsFilters | 'placeholder' | 'unsaved';
  operator: FILTER_OPERATORS;
}
export interface FilterOption {
  id: string;
  label: string;
}
export interface ProductQuestionsFilters {
  collections?: FilterOption[];
  metadata?: FilterOption[];
  productType?: FilterOption[];
  productName?: string;
  questions?: string;
}
export interface HandleQuestionChangeParams {
  question?: string;
  item: ProductQuestions;
  field: `previewQuestions[${number}]`;
  index: number;
  answer?: string | null;
}

export type HandleQuestionChange = (params: HandleQuestionChangeParams) => void;
