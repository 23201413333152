import { ProtectedRoute } from 'components';

export const WithRouteProtected = ({
  children,
  protected: protectedRoute,
}: {
  children: JSX.Element | JSX.Element[];
  protected: boolean;
}): JSX.Element => {
  if (protectedRoute) {
    return (
      <ProtectedRoute>
        <>{children}</>
      </ProtectedRoute>
    );
  }

  return <>{children}</>;
};
