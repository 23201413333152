import { FlagsmithFeatures } from 'utils/flagsmith';

export enum RoutePaths {
  HOME_PAGE = '/',
  ASSISTANT_PAGE = '/assistant',
  PRODUCT_QUESTIONS_PAGE = '/product-questions',
  PRODUCT_QUESTIONS_SETTINGS_PAGE = '/product-questions/settings',
  SIGN_UP_PAGE = '/sign-up',
  ONBOARDING = '/onboarding',
  CREATE_ORGANIZATION = '/create-organization',
  INVITATION = '/:organizationSlug/invitation/:challenge',
  LOGIN = '/login',
  FINISH_SIGNIN = '/sign-in/:challenge',
  SETTINGS = '/settings',
  MESSAGES = '/messages',
  USER_PROFILE = '/profile',
  MESSAGE = '/messages/:id',
  CREATE_MESSAGE = '/messages/new',
  EDIT_MESSAGE = '/messages/:id/edit',
  LIBRARY = '/library',
  INBOX = '/inbox',
  SETTINGS_GENERAL = '/settings/general',
  SETTINGS_SETUP = '/settings/setup',
  SETTINGS_PROFILE = '/settings/profile',
  SETTINGS_MEMBERS = '/settings/members',
  SETTINGS_USAGE = '/settings/usage',
  SETTINGS_CUSTOMIZATION = '/settings/ai-expert',
  SETTINGS_WIDGET = '/settings/widget',
  DASHBOARDS_PAGE = '/dashboards',
  DASHBOARDS_COCKPIT_PAGE = '/dashboards/cockpit',
  PLAYGROUND = '/playground',
}

export interface IRoutes {
  path: string;
  translationKey?: string;
  displayInNavbar?: boolean;
  displayNavbar?: boolean;
  flagsmithFeature?: string;
}

export const commonRoutes: IRoutes[] = [
  {
    path: RoutePaths.SETTINGS_PROFILE,
    translationKey: 'userProfile.profile',
    displayNavbar: true,
  },
  {
    path: RoutePaths.SETTINGS,
    translationKey: 'settings.title',
    displayNavbar: true,
    displayInNavbar: true,
  },
  {
    path: RoutePaths.SETTINGS_PROFILE,
    translationKey: 'userProfile.profile',
    displayNavbar: true,
  },
  {
    path: RoutePaths.SIGN_UP_PAGE,
  },
  {
    path: RoutePaths.ONBOARDING,
  },
  {
    path: RoutePaths.LOGIN,
  },
  {
    path: RoutePaths.FINISH_SIGNIN,
  },
];

export const eCommerceRoutes: IRoutes[] = [
  ...commonRoutes,
  {
    path: RoutePaths.PRODUCT_QUESTIONS_PAGE,
    translationKey: 'productQuestions.title',
    displayInNavbar: true,
    displayNavbar: true,
  },
  {
    path: RoutePaths.DASHBOARDS_PAGE,
    translationKey: 'dashboards.title',
    displayInNavbar: true,
    displayNavbar: true,
    flagsmithFeature: FlagsmithFeatures.DASHBOARDS_COCKPIT,
  },
  {
    path: RoutePaths.PLAYGROUND,
    translationKey: 'playground.title',
    displayInNavbar: true,
    displayNavbar: true,
  },
];
export const mainRoutes: IRoutes[] = [
  ...commonRoutes,
  {
    path: RoutePaths.HOME_PAGE,
    translationKey: 'home.title',
    displayInNavbar: true,
    displayNavbar: true,
  },
];

export default RoutePaths;
