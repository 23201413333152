import {
  WithOnlyLogout,
  WithOrganization,
  WithRouteProtected,
  WithSuspense,
} from 'hocs';

export const RouteBuilder = ({
  suspense,
  onlyLoggedOut,
  protected: protectedRoute,
  element,
  requireOrganization,
}: {
  onlyLoggedOut: boolean;
  suspense: boolean;
  protected: boolean;
  element: JSX.Element;
  requireOrganization: boolean;
}): JSX.Element | JSX.Element[] => {
  return (
    <WithSuspense suspense={suspense}>
      <WithRouteProtected protected={protectedRoute}>
        <WithOnlyLogout onlyLogout={onlyLoggedOut}>
          <WithOrganization requireOrganization={requireOrganization}>
            {element}
          </WithOrganization>
        </WithOnlyLogout>
      </WithRouteProtected>
    </WithSuspense>
  );
};
